* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbarmenu {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    background-color: #892C1B;
}

.logo p {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: 700;
}

.logo p a {
    text-decoration: none;
    color: white;
}

.links ul {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 25px;
    list-style-type: none;
}

.links ul li a {
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.login i {
    font-size: 30px;
    color: white;
    font-weight: 600;
}

.mobile-navbar {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #892C1B;
}

.mobile-navbar ul {
    list-style-type: none;
    padding: 0;
}

.mobile-navbar ul li {
    margin: 10px 0;
}

.mobile-navbar ul li a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.mobile-menu-icon {
    display: none;
    font-size: 23px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    background-color: #892C1B;
}

@media (max-width: 768px) {
    .navbarmenu {
        display: none;
    }
    .mobile-navbar {
        display: flex;
    }
    .mobile-menu-icon {
        display: block;
    }
}
