.banre {
    margin-top: 30px;
}

.w-100 {
    height: 450px;
}
.btnsign{
    background-color: #892C1B;
    padding: 5px;
    border-radius: 5px;
    border: 0px;
    color: white;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
}
.signupheadinh{
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
    color: #892C1B;
}
.formrow{
    background-image: url(../Images/bg.jpg);
    padding: 0px 30px;
}
@media(max-width:768px) {
    .w-100 {
        height: 204px;
    }
    .carousel-indicators {
        top: 200px;
    }
    .carousel-indicators [data-bs-target] {
    background-color: black;
    }
    .formrow{
        padding: 0px 10px;
    }
}