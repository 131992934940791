.logincomponent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 40px; */
    margin-top: 40px;
}
.loginbox{
    width: 60%;
    background-color: #f1f1f1;
    padding: 40px;
    margin-bottom: 30px;
}
.loginbox button{
    background-color: #892C1B;
    padding: 5px;
    width: 100%;
    color: white;
    font-weight: 700;
    font-size: 17px;
    border: 0px;
    border-radius: 5px;
}
.loginbox div span{
    float: right;
}
.loginbox div span a{
    color: red;
}

@media(max-width:768px){
    .loginbox{
        width: 100%;
        background-color: #f1f1f1;
        padding: 40px;
        margin-bottom: 30px;
    }
}